.wpwl-control {
  &.wpwl-control-brand {
    height: 34px !important;
  }
}

.wpwl-form {
  max-width: 100%;
}

.wpwl-button {
  &.wpwl-button-pay {
    display: none !important;
  }
} 

.wpwl-label {
  &.wpwl-label-brand {
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.wpwl-label-cardNumber {
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.wpwl-label-expiry {
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.wpwl-label-cardHolder {
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.wpwl-label-cvv {
    height: 34px;
    display: flex;
    align-items: center;
  }
}

.wpwl-control {
  &.wpwl-control-brand {
    @extend .Select-control;
    height: 34px !important;
  }

  &.wpwl-control-iframe {
    &.wpwl-control-cardNumber {
      @extend .Select-control;
      height: 34px !important;
    }

    &.wpwl-control-cvv {
      @extend .Select-control;
      height: 34px !important;
    }
  }

  &.wpwl-control-expiry {
    @extend .Select-control;
    height: 34px !important;
  }

  &.wpwl-control-cardHolder {
    @extend .Select-control;
    height: 34px !important;
  }
}

