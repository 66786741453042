.align-right {
  text-align: right;
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}

.hidden {
  display: none;
}
