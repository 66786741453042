//
// Select Menu
// ------------------------------

// wrapper around the menu

.Select-menu-outer {
  // Unfortunately, having both border-radius and allows scrolling using overflow defined on the same
  // element forces the browser to repaint on scroll.  However, if these definitions are split into an
  // outer and an inner element, the browser is able to optimize the scrolling behavior and does not
  // have to repaint on scroll.
  border-bottom-right-radius: $select-input-border-radius;
  border-bottom-left-radius: $select-input-border-radius;

  background-color: $select-input-bg;
  //border: 1px solid $select-input-border-color;
  // border-top-color: mix($select-input-bg, $select-input-border-color, 50%);
  box-shadow: $select-menu-box-shadow;
  box-sizing: border-box;
  //margin-top: -1px;
  max-height: $select-menu-max-height;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: $select-menu-zindex;
  -webkit-overflow-scrolling: touch;
  margin-top: 4px;
  box-shadow: 0 0 10px 0 #c3c3c3;
  border-radius: $border-radius;
}

// wrapper

.Select-menu {
  max-height: ($select-menu-max-height - 2px);
  overflow-y: auto;
}

// options

.Select-option {
  box-sizing: border-box;
  background-color: $select-option-bg;
  color: $select-option-color;
  cursor: pointer;
  display: block;
  padding: $select-padding-vertical $select-padding-horizontal;
  padding-left: 20px;
  font-size: 16px;

  &:last-child {
    border-bottom-right-radius: $select-input-border-radius;
    border-bottom-left-radius: $select-input-border-radius;
  }

  &.is-selected {
    background-color: $select-option-selected-bg-fb;
    /* Fallback color for IE 8 */
    background-color: $select-option-selected-bg;
    color: $select-option-selected-color;

    padding-left: 4px;

    &::before {
      // background-image: url('/assets/images/icons/icon-16-tick-grey.svg');
      display: inline-block;
      width: 16px;
      height: 16px;
      content: ' ';
      position: relative;
      top: 4px;
    }
  }

  &.is-focused {
    background-color: $select-option-focused-bg-fb;
    /* Fallback color for IE 8 */
    background-color: $select-option-focused-bg;
    color: $select-option-focused-color;
  }

  &.is-disabled {
    color: $select-option-disabled-color;
    cursor: default;
  }
}

// no results

.Select-noresults {
  box-sizing: border-box;
  color: $select-noresults-color;
  cursor: default;
  display: block;
  padding: $select-padding-vertical $select-padding-horizontal;
}
