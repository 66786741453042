h1 {
  font-weight: $h1-font-weight;
  margin-bottom: $h1-margin-bottom;
  line-height: $h1-line-height;
}
h2 {
  font-weight: $h2-font-weight;
  margin-bottom: $h2-margin-bottom;
  line-height: $h2-line-height;
}
h3 {
  font-weight: $h3-font-weight;
  margin-bottom: $h3-margin-bottom;
  line-height: $h3-line-height;
}
h4 {
  font-weight: $h4-font-weight;
  margin-bottom: $h4-margin-bottom;
  line-height: $h4-line-height;
}
h5 {
  font-weight: $h5-font-weight;
  margin-bottom: $h5-margin-bottom;
  line-height: $h5-line-height;
}
h6 {
  font-weight: $h6-font-weight;
  margin-bottom: $h6-margin-bottom;
  line-height: $h6-line-height;
}
h6.h7 {
  font-size: $h7-font-size;
  font-weight: $h7-font-weight;
  margin-bottom: $h7-margin-bottom;
  line-height: $h7-line-height;
}
h6.h8 {
  font-size: $h8-font-size;
  font-weight: $h8-font-weight;
  margin-bottom: $h8-margin-bottom;
  line-height: $h8-line-height;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h7,
.h8 {
  &.bold {
    font-weight: $font-weight-bold;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.inline {
    display: inline;
  }

  &.light {
    color: $faded-color-dark;
  }
}
