@import './select';
@import 'flags/flag-icon';

.input-wrapper {
  &.inline {
    display: inline-block;
    padding-top: 1px;

    .form-check-input {
      display: inline-block;
      width: auto;
      margin-right: 12px;
      margin-left: 0;
    }
  }

  &.invalid {
    position: relative;

    &:after {
      // background: url('/assets/images/forms/warning.svg');
      content: ' ';
      width: 16px;
      height: 16px;
      position: absolute;
      right: 9px;
      top: 9px;
    }
  }

  &.select.invalid {
    &:after {
      right: 42px;
    }
  }
}

.form-group {
  small {
    display: block;
    margin-top: 8px;
    margin-left: 2px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }
  .description {
    margin-top: -18px;
    color: #3d3d3d;
    font-weight: inherit;
    margin-left: 0 !important;
  }

  &.is-checkbox input {
    width: 13px !important;
  }
}

label.bold {
  font-weight: $font-weight-bold;
}

.inline-label {
  border-bottom: $border;
  padding-bottom: 16px;

  label {
    display: inline-block;
    padding-right: 32px;

    & + .inline-radio {
      display: inline-block;
    }
  }
}

.inline-radio {
  .form-check {
    display: inline-block;
    padding-right: 32px !important;

    .form-check-label {
      display: inline-block;

      input {
        display: inline-block;
        width: 24px;
        position: relative;
        top: -1px;
      }

      div {
        display: inline-block;
      }
    }
  }
}

.form-check {
  padding-left: 0px !important;

  & + .form-check {
    margin-top: 8px;
  }

  &:hover {
    cursor: pointer;

    .form-check-label {
      cursor: pointer;

      & input {
        cursor: pointer;
      }
    }
  }

  .form-check-label {
    display: flex;
    padding-left: 20px;
    padding-top: 8px;

    input {
      flex: 0 0 24px;
      margin-top: 2px;
    }
  }
}

form {
  .btn {
    margin-left: 24px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.form-group > .no-margin {
  .form-group {
    margin-left: 0;

    & + .form-group {
      margin-left: 0 !important;
    }
  }
}

.form-group-half {
  display: flex;

  .form-group {
    flex: 1;

    & + .form-group {
      margin-left: 4%;
    }
  }

  &-spacer {
    flex: 1;
    margin-left: 4%;
  }
}

.overlay-form {
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 4;
  background: $white;
  @include fadeInAnimation;

  &.loading {
    .overlay-form-header {
      h3 span svg {
        pointer-events: none;

        &:hover {
          cursor: unset;

          g {
            stroke: unset;
          }
        }
      }
    }
  }

  .spacer-left {
    //flex: 0 0 304px;
    flex: 2;

    &.padded {
      padding-left: 20px;
    }

    &.full-width {
      flex: 0;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .spacer-right {
    flex: 2;

    &.full-width {
      flex: 0;
    }

    &.padded {
      padding-right: 20px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .overlay-form-inner-content {
    flex: 3;
    min-width: 480px;
    max-width: 700px;

    &.full-width {
      max-width: 100%;
    }

    &.align-left {
      margin-left: -10px;
      display: flex;
    }
  }

  .overlay-form-inner-content-extended-width {
    flex: 5;

    max-width: 816px;
  }

  .overlay-form-inner-content-extended-width,
  .overlay-form-inner-content {
    & > .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-header {
    border-bottom: $border;
    flex: 0 0 auto;
    padding: 24px 0;
    display: flex;

    h3 {
      margin: 0;
      position: relative;
      user-select: none;

      span {
        svg {
          position: absolute;
          top: 3px;
          right: 0;

          &:hover {
            cursor: pointer;

            g {
              stroke: $primary;
            }
          }
        }
      }
    }
  }

  &-content {
    flex: 1;
    display: flex;
    overflow-x: auto;

    &.padded {
      padding-top: 24px;
      overflow-y: auto;
    }
  }

  &-footer {
    flex: 0 0 auto;
    display: flex;

    button {
      min-width: 140px;

      & + button {
        float: right;
      }

      &:not(:first-child):not(:last-child) {
        margin-left: 12px;
      }
    }
  }
}

input[type='checkbox'] {
  opacity: 0;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

.checkbox-label-disabled {
  user-select: none;
  position: relative;

  &::before {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: $white;
    opacity: 1;
    border: 2px solid darken($border-color, 20%);
    border-radius: 3px;
    content: ' ';
    position: absolute;
    left: -25px;
    top: 3px;
  }

  &.checked {
    &::before {
      background-image: url('/assets/icon-24-px-cross.svg');
      background-size: 148%;
      background-position-x: -2px;
      background-position-y: -2px;
    }
  }

  &.zero {
    &::before {
      left: 0;
    }
  }

  &.twenty {
    &::before {
      left: 20px;
    }
  }
}

.checkbox-label {
  user-select: none;
  cursor: pointer;
  position: relative;

  &::before {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: $white;
    opacity: 1;
    border: 2px solid darken($border-color, 20%);
    border-radius: 3px;
    content: ' ';
    position: absolute;
    left: -25px;
    top: 3px;
  }

  &.checked {
    &::before {
      background: red;
      border-color: red;
      background-image: url('/assets/icon-24-px-cross.svg');
      background-size: 148%;
      background-position-x: -2px;
      background-position-y: -2px;
    }
  }

  &.zero {
    &::before {
      left: 0;
    }
  }

  &.twenty {
    &::before {
      left: 20px;
    }
  }
}
