@import './styles/styles';
@import './forms.scss';
@import '../overrides.scss';
@import './peach-payments.scss';
@import '../components/button/button.scss';
@import './datepicker/datepicker.scss';

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.invalid-feedback {
  width: 100%;
  margin-top: 8px;
  font-size: 80%;
  display: flex !important;
}

.overlay-form-sanbox {
  display: flex;
  flex-direction: row;
  z-index: 4;
  height: 100%;
}
.spacer-left {
  flex: 2;

  @media (max-width: 768px) {
    flex: 1;
  }
}
.spacer-right {
  flex: 2;

  @media (max-width: 768px) {
    flex: 1;
  }
}
.overlay-form-inner-content {
  flex: 3;

  display: flex;
  justify-content: center;
  flex-direction: column;
}
.sandbox-success-contents {
  font-size: 16px;
  font-weight: 300;
  color: rgba(31, 44, 70, 1);
  font-family: 'Roboto';
}
.sandbox-success-heading {
  font-size: 30px;
  font-weight: 900;
  color: rgba(31, 44, 70, 1);
  margin-bottom: 14px;
  margin-top: 14px;
  font-family: 'roboto';
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-container-div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
