.error-block {
  color: $danger;
  margin-bottom: 24px;
}

.warning-block {
  color: $warning;
  margin-bottom: 24px;
}

.success-block {
  color: $success;
  margin-bottom: 24px;
}

.primary-block {
  color: $primary;
  margin-bottom: 24px;
}

.warning {
  color: $warning;
}
