.loading-dots {
    align-items: center;
    justify-content: center;
    left: calc((100% - 40px) / 2);
  
    .dot-primary {
      width: 5px;
      height: 5px;
      background-color: rgba(255, 255, 255, 1);     
      display: inline-block;
      border-radius: 50%;
      margin: 0 3px;
      animation-fill-mode: both;
      animation: fader-blue 1.8s infinite ease-in-out;
      vertical-align: middle;
  
      &.dot-one {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
  
      &.dot-two {
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
    }
  
    .dot {
      width: 5px;
      height: 5px;
      background-color: rgba(255, 255, 255, 1);      
      display: inline-block;
      border-radius: 50%;
      margin: 0 3px;
      animation-fill-mode: both;
      animation: fader 1.8s infinite ease-in-out;
      vertical-align: middle;
  
      &.dot-one {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
  
      &.dot-two {
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
    }
  }
  
  @keyframes fader-blue {
    0%,
    100%,
    80% {
      background-color: rgba(255, 255, 255, 0);
    }
  
    40% {
      background-color: #4078fa;    
    }
  }
  
  @keyframes fader {
    0%,
    100%,
    80% {
      background-color: rgba(255, 255, 255, 0);
    }
  
    40% {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  